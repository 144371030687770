<template lang="pug">
  v-container(fluid)
    .split
      .split__header
        .split__title.split__title_one-word(@click='photo_page = true', :class='{ active: photo_page }') Фото поверхностей
      .split__header
        .split__title(@click='photo_page = false', :class='{ active: !photo_page }') Фотоотчет
    template(v-if='photo_page')
      .d-flex.mt-10.mb-9
        .width-input.mr-13
          v-text-field(
            v-model="gids"
            dense,
            hide-details,
            outlined,
            label='Идентификатор',
            type='text'
          )
        .width-input.mr-13
          v-autocomplete(
            v-model='supplier',
            :items='publisherName',
            label='Поставщик поверхности',
            no-data-text='Поставщик не найден',
            color='accent',
            dense,
            flat,
            outlined,
            hide-details,
            item-text='name',
            item-value='id',
            append-icon='mdi-chevron-down'
          )
        .width-input
          v-autocomplete.filter(
            v-model='city',
            :items='getCities',
            label='Город',
            no-data-text='Город не найден',
            color='accent',
            clearable,
            dense,
            flat,
            outlined,
            height='27',
            hide-details,
            item-text='name',
            item-value='id',
            append-icon='mdi-chevron-down'
          )
      table-surfaces-photo(:surfaces="searchSurfaces" @updateTable="surfaceUpdated")
</template>

<script>
import uploadCsvList from '@/components/campaignSave/uploadCsvList';
import export_excel from '@/components/export_excel';
import TableSurfacesPhoto from '@/components/campaignSave/tableSurfacesPhoto';
import {mapActions, mapGetters} from 'vuex';
import {GET_SURFACE_NOIMAGES} from '@/store/const/agency';
import {GET_CITIES, GET_PUBLISHERS} from '@/store/const/map';

export default {
  name: 'photo-surfaces',
  components: {
    TableSurfacesPhoto,
    uploadCsvList,
    export_excel
  },
  data() {
    return {
      photo_page: true,
      supplier: null,
      gids: null,
      city: null
    }
  },
  computed: {
    ...mapGetters('Agency', ['getSurfaceNoImages']),
    ...mapGetters('Map', ['getPublishers', 'getCities']),
    publisherName() {
      return this.getPublishers.filter((i) => Object.values(this.getSurfaceNoImages).find((r) => r.publisherId === i.id),).sort((a, b) => a.name.localeCompare(b.name));
    },
    searchSurfaces() {
      let surface = this.getSurfaceNoImages
      if (!this.empty(this.gids)) {
        surface = surface.filter(item => item.gid === this.gids)
      }
      if (!this.empty(this.supplier)) {
        surface = surface.filter(item => item.publisherId === this.supplier)
      }
      if (!this.empty(this.city)) {
        surface = surface.filter(item => item.city === this.city)
      }
      return surface
    }
  },
  async mounted() {
    await this.GET_SURFACE_NOIMAGES()
    await this.GET_PUBLISHERS()
    await this.GET_CITIES()
  },
  methods: {
    ...mapActions('Agency', [GET_SURFACE_NOIMAGES]),
    ...mapActions('Map', [GET_PUBLISHERS, GET_CITIES]),
    async surfaceUpdated() {
      await this.GET_SURFACE_NOIMAGES()
    },
    empty(e) {
      switch (e) {
        case "":
        case 0:
        case "0":
        case null:
        case false:
        case typeof this == "undefined":
          return true;
        default:
          return false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.split {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;

  :last-child &__header {
    padding-right: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 60px 0 5px;
    transform: translateY(1px);
  }

  &__header-old {
    width: 50%;
  }

  &__title {
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 2px solid transparent;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      border-color: #d6d6d6;
    }

    &.active {
      border-color: #4a55b0;
    }
  }
}

.width-input {
  width: 210px;
}
</style>