<template lang="pug">
  .surfaces
    table
      thead
        tr
          template(v-for='header in headers')
            td.header_cell.pointer {{header.label}}
      tbody.table_body
        template(v-for="surface in getSurfaces")
          tr.table_row(:key="surface.id")
            td.table_cell(v-for="header in headers")
              span(v-if="header.value === 'description'") {{ surface.hasOwnProperty('description') ? surface.description : surface.address }}
              span(v-else-if='header.value === "publisherId"') {{publisherName(surface[header.value])}}
              div(v-else-if="header.value === 'actions'")
                v-btn(
                  elevation='0',
                  color='accent',
                  @click='handleButtonClick'
                  :loading="isLoading"
                ) Загрузить фото
                input.d-none(ref='uploader', name='uploader' type='file', accept='image/png, image/jpeg', @change='handleFileUpload($event, surface.id)')
              span(v-else) {{surface[header.value]}}
    v-pagination.mt-2(v-if="pageCount > 1" v-model="page" :length="pageCount" :total-visible="7")
</template>

<script>
import Tooltip from '@/components/tooltip';
import {mapActions, mapGetters} from 'vuex';
import {SURFACE_UPLOAD_IMAGE} from '@/store/const/agency';
import loading from '@/mixins/loading_mixin';
export default {
  name: 'tableSurfacesPhoto',
  components: {Tooltip},
  mixins: [loading],
  props: {
    surfaces: {
      type: Array,
    }
  },
  data() {
    return {
      page: 1,
      per_page: 10,
      headers: [
        {label: 'GID (идентификатор)', value: 'gid'},
        {label: 'ID', value: 'id'},
        {label: 'Адрес', value: 'description'},
        {label: 'Поставщик', value: 'publisherId'},
        {label: 'Сторона', value: 'side'},
        {label: 'Город', value: 'city'},
        {label: 'Тип поверхности', value: 'type'},
        {value: 'actions'},
      ],
      isSelecting: false,
      file: null,
      id: null
    }
  },
  computed: {
    ...mapGetters('Map', ['getPublishers']),
    getSurfaces() {
      let start = (this.page - 1) * this.per_page
      return this.surfaces.slice(start, start + this.per_page)
    },
    pageCount() {
      if (this.surfaces.length) {
        const surfaces = this.surfaces
        return Math.ceil(surfaces.length / this.per_page)
      }
    },
  },
  mounted() {
    this.setLoadingActions()
  },
  methods: {
    ...mapActions('Agency', [SURFACE_UPLOAD_IMAGE]),
    setLoadingActions() {
      this.actions = [
        SURFACE_UPLOAD_IMAGE
      ]
    },
    handleButtonClick() {
      this.$refs.uploader[0].click()
    },
    async handleFileUpload(e, surfaceId) {
      try {
        if (e.target.files[0] && surfaceId.length) {
          this.file = e.target.files[0];
          const fd = new FormData();
          fd.append('file', this.file)
          const id = surfaceId
          const file = fd
          await this.SURFACE_UPLOAD_IMAGE({id, file})
          this.$notify({
            type: 'success',
            text: 'Фото успешно загружено',
          });
          this.$emit('updateTable')
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Ошибка загрузки',
          text: 'Не удалось загрузить фото',
        });
      }
    },
    publisherName(item) {
      const publisher = this.getPublishers.find(a => a.id === item)
      return publisher ? publisher.name : item
    },
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.header_cell, .table_cell {
  padding: 10px;
  text-align: justify;
  border: 1px solid #DCDFE7;
  position: relative;
}

.header_cell:not(:nth-child(1)):not(:nth-child(4)) {
  text-align: center;
}

.header_cell {
  background: #4A55B0;
  color: #fff;
}

.action {
  color: #4A55B0;
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
}

.row-action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.delete_btn {
  width: 20px;
  height: 20px;
  border: 1px solid #4A55B0;
  border-radius: 50%;
  color: #4A55B0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_margin {
  margin-top: 5px;
}

.rating_below {
  color: #D32F2F;
}

.isCircleRed {
  display: flex;
}

.isCircleRed::after {
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border: 2px solid red;
  margin: 0;
  background: red;
  border-radius: 50%;
}

.btn-test {
  border: 1px solid #485CAA;
  border-radius: 50%;
}

.mdi-camera-two::before {
  content: "\F0100" !important;
  color: blue !important;
}
</style>
